import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
const Contact = () => {
  return (
    <Layout>
      <Head
        title="Contact"
        descriptionContent="Contact SilverGoldPill on twitter, youtube or instagram."
      />
      <h2>Contact</h2>
      <p>
        I want to thank you for checking out <b>SilverGoldPill.com</b>.
        If you'd like to reach out, feel free to contact or follow me
        on:
      </p>
      <ul>
        <li>
          <a href="https://twitter.com/silvergoldpill">
            <b>Twitter</b>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/silvergoldpill/">
            <b>Instagram</b>
          </a>
        </li>
      </ul>
      <p>
        I try to write about the latest happenings in the coin and bullion
        market, but if you think there is anything I should look into or write
        about let's get in touch!.
      </p>
      <p>Keep stackin Brother!</p>
      <br /> <br />
    </Layout>
  )
}
export default Contact
